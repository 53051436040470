@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0px;
  padding: 0px;
  background-color: #000000;
  font-family: "Poppins", sans-serif;
}
* {
  box-sizing: border-box;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  outline: none;
  border: none;
}
input {
  outline: none;
  border: none;
}
.logo img {
  width: 90px;
}
#main {
  width: 100%;
  height: 768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./assets/banner.png");
  position: relative;
  z-index: 0;
}
#main::before {
  position: absolute;
  content: "";
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(8, 77, 181, 0.38) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(8, 77, 181, 0.38) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(8, 77, 181, 0.38) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}
nav.active {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
  background-color: #000000;
}
nav ul {
  display: flex;
}
.active {
  background-color: #1472ff;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  transition: all ease 0.2s;
}
.active:hover {
  background-color: #000000;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  transition: all ease 0.2s;
}
nav ul li a {
  font-family: "Poppins";
  height: 40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
nav ul li a:hover {
  background-color: #147aff;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon {
  display: none;
}
.name {
  text-align: center;
  font-family: "Poppins";
  width: 900px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -45%);
}
.name span {
  color: #1472ff;
}
.name .details {
  font-size: 23px;
  color: #c5c5c5;
}
.name h2 {
  font-family: "Poppins";
  font-size: 2.7rem;
  margin: 0px;
  letter-spacing: 2px;
  color: #ffffff;
}
.name h1 {
  font-family: "Poppins";
  font-size: 5rem;
  margin: 0px;
  letter-spacing: 2px;
  color: #ffffff;
}
.name p{
  font-family: 'Poppins';
}
.header-btns {
  display: flex;
  margin-top: 40px;
  margin-left: 40%;
}
.header-btn {
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: #1472ff;
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  border-radius: 5px;
  color: #ffffff;
}
.header-btn:hover {
  background-color: transparent;
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid #1472ff;
}

#features {
  width: 100%;
  height: 120vh;
  box-sizing: border-box;
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  background-color: #000000;
}
#features h1 {
  color: #fff;
  font-size: 3rem;
}
.a-container {
  display: flex;
  justify-content: center;
  align-content: center;
}
.a-box {
  background-color: #000000;
  width: 250px;
  height: 400px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  position: relative;
}
.a-b-img {
  width: 100%;
  height: 60%;
}
.a-b-img img {
  padding: 15px;
  margin-top: 40px;
  width: 100%;
  height: 50%;
}
.a-box:hover {
  border: 1px solid #1472ff;
  box-shadow: 2px 2px 12px rgba(2, 112, 255, 0.336);
}
.a-b-text {
  width: 100%;
  height: 40%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.a-b-text h2 {
  color: #fff;
}
.a-b-text p {
  margin: 0px;
  color: #ffffffa6;
  font-size: 1.1rem;
  font-family: "Poppins";
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 5px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.a-b-text p:hover {
  color: #ffffff;
}
.a-b-text a {
  margin-top: 15px;
}
#presentaion {
  padding-top: 30px;
  width: 100%;
  height: 768px;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./assets/offer.png");
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center ;
}
.pr-heading {
  font-family: "Poppins";
  position: absolute;
}
.pr-heading span {
  color: #1472ff;
}
.pr-heading .details {
  font-size: 23px;
  color: #c5c5c5;
}
.pr-heading .details {
  font-size: 1.2rem;
}
.pr-heading h1 {
  font-family: "Poppins";
  font-size: 4rem;
  margin: 0px;
  letter-spacing: 2px;
  color: #ffffff;
}
.pr-btns {
  display: flex;
  margin-top: 40px;
  margin-left: 35%;
}
.pr-btn {
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: #1472ff;
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  border-radius: 5px;
  color: #ffffff;
}
.pr-btn:hover {
  background-color: transparent;
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid #1472ff;
}

#about {
  margin-top: 100px;
  width: 100%;
  height: 120vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 0px 5%;
  position: relative;
}

.about-text {
  width: 45%;
  color: #fff;
  list-style: circle;
}
.about-text h1 {
  font-size: 3rem;
  color: #1472ff;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}
.about-text p {
  width: 80%;
  font-size: 1.2rem;
  color: #cecece;
}
.about-text button {
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #1472ff;
  font-family: inherit;
}
.about-text button:hover {
  box-shadow: 5px 10px 30px rgba(2, 112, 255, 0.336);
  background-color: rgb(0, 0, 0);
  transition: all ease 0.3s;
  color: #ffffff;
}
.about-image {
  width: 50%;
}
.about-image img {
  width: 600px;
}

#contact {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#contact h1 {
  color: #fff;
  font-size: 3rem;
}
#contact form {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
#contact form input,
#contact form textarea {
  font-family: inherit;
  width: 100%;
  height: 50px;
  margin: 5px 0px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #ffffff2d;
  color: #ffffff;
  border-radius: 5px;
}
#contact form textarea {
  height: 150px;
}
#contact form input[type="submit"] {
  width: 50%;
  height: 45px;
  background: linear-gradient(90deg, #1472ff 20%, #0400f7);
  color: #ffffff;
}
@media (max-width: 620px) {
  #contact form {
    width: 90%;
  }
}

@media (max-width: 1190px) {
  #main {
    background-size: 1150px !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .name h2 {
    font-size: 1.5rem;
  }
  .name h1 {
    font-size: 3rem;
  }
  .name .details {
    font-size: 1rem;
  }
  .pr-heading {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .pr-heading h2 {
    font-size: 1.5rem;
  }
  .pr-heading h1 {
    font-size: 3rem;
  }
  .pr-heading .details {
    font-size: 1rem;
  }
  .about-image img {
    height: 400px;
  }
  #features {
    height: auto;
  }
  .a-container {
    flex-wrap: wrap;
  }
  .a-box {
    flex-grow: 1;
  }
  .a-b-img img {
    object-fit: contain;
  }
}

@media (max-width: 970px) {
  #main {
    height: 650px;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .pr-heading {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .about-image {
    display: none;
  }
  .header-btn {
    margin-left: 20%;
    margin-top: 20px;
  }
  .pr-btn {
    margin-left: 20%;
    margin-top: 20px;
  }
  #about {
    justify-content: center;
    padding-top: 0px;
    text-align: center;

    margin-top: 0px;
  }
  .about-text {
    width: 90%;
  }
  .about-text h1 {
    font-size: 4rem;
  }
  .about-text p {
    width: 100%;
  }
  #features {
    margin-bottom: 100px;
  }
}
@media (max-width: 600px) {
  .name {
    width: 60%;
  }
  .pr-heading {
    width: 60%;
  }
}

@media (max-width: 1100px) {
  .menu-icon {
    display: block;
  }
  nav {
    justify-content: space-between;
    height: 65px;
    padding: 0px 30px;
  }
  .logo img {
    width: 70px;
  }
  .header-btns {
    margin: 0;
  }
  .pr-btns {
    margin: 0;
  }
  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0px;
    background-color: #000000;
    border-bottom: 4px solid #1472ff;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .menu li {
    width: 100%;
  }
  nav .menu li a {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }
  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  nav .menu-icon .nav-icon {
    background-color: #ffffff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 24px;
  }
  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: rgb(255, 255, 255);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  nav .menu-icon .nav-icon:before {
    top: 6px;
  }
  nav .menu-icon .nav-icon:after {
    top: -6px;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
  }
  nav .menu-btn {
    display: none;
  }
  nav .menu-btn:checked ~ .menu {
    display: block;
  }
}
@media (max-width: 400px) {
  .a-box {
    width: 100%;
    height: 400px;
  }
}
